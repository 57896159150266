// 3rd
import { z } from 'zod';

// App - Types
import {
  KnowledgeSourcesDto,
  castKnowledgeSourceDtoToKnowledgeSource,
} from '@/types/integration/dtos/knowledge-source';
import {
  IntegrationStatusesDto,
  castIntegrationStatusDtoToIntegrationStatus,
} from '@/types/integration/dtos/integration-status';
import type { KnowledgeSourceIntegration } from '../../types/knowledge-source-integration';

// App - Other
import { normalizeDatesToISOString } from '@/utils/format';

export const ZodIntegrationDto = z.object({
  id: z.string(),
  displayName: z.string(),
  lastScraped: z.string().optional(),
  projectNames: z.string().array().optional(),
  knowledgeSourceType: z.enum(KnowledgeSourcesDto),
  integrationStatus: z.enum(IntegrationStatusesDto),
});

export type IntegrationDto = z.infer<typeof ZodIntegrationDto>;

export const castDtoToKnowledgeSourceIntegration = (
  dto: IntegrationDto
): KnowledgeSourceIntegration => ({
  id: dto.id,
  displayName: dto.displayName,
  lastScraped: dto.lastScraped ? normalizeDatesToISOString(dto.lastScraped) : undefined,
  projectWhitelist: dto.projectNames,
  source: castKnowledgeSourceDtoToKnowledgeSource(dto.knowledgeSourceType),
  status: castIntegrationStatusDtoToIntegrationStatus(dto.integrationStatus),
});
