export const INTEGRATIONS_QUERY_KEYS = {
  all: ['integrations'] as const,
  integrations: () => [...INTEGRATIONS_QUERY_KEYS.all] as const,
  slackIntegrations: () => [...INTEGRATIONS_QUERY_KEYS.all, 'slack'] as const,

  azureDevOpsWorkItemsProjects: (knowledgeSourceConfigurationId: string) =>
    [
      ...INTEGRATIONS_QUERY_KEYS.all,
      'azureDevOpsWorkItemsProjects',
      knowledgeSourceConfigurationId,
    ] as const,
};
