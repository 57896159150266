// 3rd
import { z } from 'zod';

export const passthroughInvalidItemsArray = <T>(
  schema: z.ZodType<T>,
  options?: { ignore?: boolean }
) => {
  return z
    .any()
    .array()
    .transform((arr) =>
      arr
        .map((item) => {
          const itemParsed = schema.safeParse(item);

          if (itemParsed.success) {
            return item as T;
          } else {
            if (!options?.ignore) {
              console.error(
                'Invalid item in array',
                item,
                itemParsed.error.issues.map((issue) => issue.message).join(', ')
              );
            }

            return undefined;
          }
        })
        .filter((item): item is T => Boolean(item))
    );
};
