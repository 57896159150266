// 3rd
import { z } from 'zod';

// App - Types
import type { CommunicationSourceIntegration } from '../../types/communication-source-integration';

export const ZodSlackIntegrationDto = z.object({
  id: z.string(),
  integrationName: z.string(),
  integrationType: z.literal('Slack'),
});

export type SlackIntegrationDto = z.infer<typeof ZodSlackIntegrationDto>;

export const castSlackDtoToCommunicationSourceIntegration = (
  dto: SlackIntegrationDto
): CommunicationSourceIntegration => ({
  id: dto.id,
  email: dto.integrationName,
  source: 'slack',
});
