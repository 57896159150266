// App - Types
import type { IntegrationStatus } from '../integration-status';

export const IntegrationStatusesDto = [
  'InstallationRemoved',
  'PendingInstallation',
  'Valid',
  'UNKNOWN',
] as const;

export type IntegrationStatusDto = (typeof IntegrationStatusesDto)[number];

export const castIntegrationStatusDtoToIntegrationStatus = (
  dto: IntegrationStatusDto
): IntegrationStatus => {
  if (dto === 'InstallationRemoved') return 'installation_removed';

  if (dto === 'PendingInstallation') return 'pending_installation';

  if (dto === 'Valid') return 'valid';

  return 'UNKNOWN';
};

export const castIntegrationStatusToIntegrationStatusDto = (
  status: IntegrationStatus
): IntegrationStatusDto => {
  if (status === 'installation_removed') return 'InstallationRemoved';

  if (status === 'pending_installation') return 'PendingInstallation';

  if (status === 'valid') return 'Valid';

  return 'UNKNOWN';
};
