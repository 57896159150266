// App - Types
import type { CommunicationSource } from '@/types/integration/communication-source';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators.integration['knowledge-source'];

export const translateCommunicationSource = (source: CommunicationSource): string => {
  if (source === 'slack') return locale['Slack'];

  return locale['Unknown'];
};
