// React & Next
import { createElement } from 'react';

// App - Types
import type { CommunicationSource } from '@/types/integration/communication-source';
import type { IconProps } from '@/components/atoms/icon';

// App - Other
import { SlackIcon, UnknownIcon } from '@/components/atoms/icon';
import { translateCommunicationSource } from './communication-source';

type CommunicationSourceIconProps = Partial<IconProps> & {
  source: CommunicationSource;
};

export const CommunicationSourceIcon = ({ source, ...iconProps }: CommunicationSourceIconProps) => {
  return createElement(translateCommunicationSourceToIcon(source), {
    'aria-label': translateCommunicationSource(source),
    ...iconProps,
  });
};

export const translateCommunicationSourceToIcon = (source: CommunicationSource) => {
  if (!source) return UnknownIcon;

  if (source === 'slack') return SlackIcon;

  return UnknownIcon;
};

export const translateCommunicationSourceToColorableIcon = (source: CommunicationSource) => {
  if (!source) return UnknownIcon;

  return UnknownIcon;
};
