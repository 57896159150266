// React & Next
import NextLink from 'next/link';
import type { ReactNode } from 'react';

// 3rd
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';

// App - Other
import Locale from '@/locale/en.json';
import { ArrowRightIcon } from '@/components/atoms/icon';

const locale = Locale.components.breadcrumbs;

export type CrumbItem = {
  url: string;
  label: string | ReactNode;
  isActive: boolean;
  isDisabled?: boolean;
};

export type BreadcrumbsProps = {
  crumbs: CrumbItem[];
};

export const Breadcrumbs = ({ crumbs }: BreadcrumbsProps) => {
  return (
    <Breadcrumb
      spacing="4px"
      separator={
        <ArrowRightIcon
          color="#57606A80"
          size="2xs"
          mb="2px"
          aria-label={locale['Breadcrumb path']}
        />
      }
    >
      {crumbs.map(({ label, url, isActive, isDisabled }, index) => {
        // const linkColor = isActive ? '#57606A' : '#57606A80';
        const linkColor = isActive ? '#57606A' : '#57606A80';

        return (
          <BreadcrumbItem key={index} isCurrentPage={isActive}>
            <BreadcrumbLink
              as={isActive || isDisabled ? Box : NextLink}
              href={url}
              textStyle="detail"
              // fontSize="12px"
              // fontWeight={400}
              // lineHeight="24px"
              color={linkColor}
              textDecoration="none"
            >
              {label}
            </BreadcrumbLink>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};
