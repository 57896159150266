// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Other
import { apiClient } from '@/config/lib/api-client';

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  url: z.string(),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodCreateGithubAppOAuthUrlResponseDto };
export type { ResponseDto as CreateGithubAppOAuthUrlResponseDto };

// #######
// Request
// #######

export const createGithubAppOAuthUrl = async (): Promise<string> => {
  try {
    const res = await apiClient.get(`/Integration/GetGithubAppInstallUrl`);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.url;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCreateGithubAppOAuthUrl = {
  onCreateStart?: () => void;
  onCreateSuccess?: (authUrl: string) => void;
  onCreateFailed?: (error: Error) => void;
};

export const useCreateGithubAppOAuthUrl = ({
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseCreateGithubAppOAuthUrl = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async () => await createGithubAppOAuthUrl(),
    onMutate: () => onCreateStart?.(),
    onSuccess: (authUrl) => onCreateSuccess?.(authUrl),
    onError: (error) => onCreateFailed?.(error),
  });

  return {
    createAppOAuthUrl: mutate,
    isCreatingAppOAuthUrl: isPending,
    didAppOAuthUrlCreationErrored: isError,
  };
};
