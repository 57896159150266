// 3rd
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { z } from 'zod';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { INTEGRATIONS_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  knowledgeSourceConfigurationId: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodGetAzureDevOpsWorkItemProjectsRequestPayloadDto };
export type { RequestPayloadDto as GetAzureDevOpsWorkItemProjectsRequestPayloadDto };

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  projectNames: z.string().array().default([]).catch([]),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodGetAzureDevOpsWorkItemProjectsResponseDto };
export type { ResponseDto as GetAzureDevOpsWorkItemProjectsResponseDto };

// #######
// Request
// #######

export const getAzureDevOpsWorkItemProjects = async (
  knowledgeSourceConfigurationId: string
): Promise<string[]> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      knowledgeSourceConfigurationId,
    });
    const res = await apiClient.post(`/AzureDevOps/ListProjects`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.projectNames;
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status === 404) {
      return [];
    }

    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseAzureDevOpsWorkItemProjects = {
  knowledgeSourceConfigurationId?: string;
  enabled?: boolean;
};

export const useAzureDevOpsWorkItemProjects = ({
  knowledgeSourceConfigurationId,
  enabled = true,
}: UseAzureDevOpsWorkItemProjects = {}) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: INTEGRATIONS_QUERY_KEYS.azureDevOpsWorkItemsProjects(knowledgeSourceConfigurationId!),
    queryFn: async () => await getAzureDevOpsWorkItemProjects(knowledgeSourceConfigurationId!),
    enabled: !!knowledgeSourceConfigurationId && !!enabled,
  });

  return {
    projectNames: data,
    isFetchingProjects: isLoading || isFetching,
    didFetchingProjectsErrored: isLoadingError,
  };
};
