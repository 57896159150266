// React & Next
import type { ReactNode } from 'react';

// 3rd
import { Flex } from '@chakra-ui/react';
import type { StyleProps } from '@chakra-ui/react';

// App - Types
import type { BreadcrumbsProps } from '@/components/organisms/breadcrumbs';

// App - Other
import { Heading } from '@/components/atoms/typography';
import { Breadcrumbs } from '@/components/organisms/breadcrumbs';

type PageLayoutProps = StyleProps & {
  children: ReactNode;
};

export const FlexiblePageLayout = ({ children, ...styleProps }: PageLayoutProps) => {
  return (
    <Flex
      direction="column"
      alignItems="flex-start"
      alignSelf="stretch"
      gap="lg"
      py="2xl"
      px="3xl"
      {...styleProps}
    >
      {children}
    </Flex>
  );
};

type BreadcrumbsBarProps = BreadcrumbsProps;

const BreadcrumbsBar = ({ crumbs }: BreadcrumbsBarProps) => {
  return <Breadcrumbs crumbs={crumbs} />;
};

FlexiblePageLayout.BreadcrumbsBar = BreadcrumbsBar;

type HeaderProps = StyleProps & {
  children: ReactNode;
};

const Header = ({ children, ...styleProps }: HeaderProps) => {
  return (
    <Flex alignSelf="stretch" alignItems="center" gap="lg" pb="sm" {...styleProps}>
      {children}
    </Flex>
  );
};

FlexiblePageLayout.Header = Header;

type HeaderHeadingProps = {
  children: string | ReactNode;
};

const HeaderHeading = ({ children }: HeaderHeadingProps) => (
  <Heading variant="h3">{children}</Heading>
);

FlexiblePageLayout.Heading = HeaderHeading;

type ContentProps = StyleProps & {
  children: ReactNode;
};

const Content = ({ children, ...styleProps }: ContentProps) => {
  return (
    <Flex direction="column" alignSelf="stretch" gap="2xl" {...styleProps}>
      {children}
    </Flex>
  );
};

FlexiblePageLayout.Content = Content;
