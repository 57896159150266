// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { UpdateAzureDevOpsIntegration } from '../types/azure-devops-integration';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { INTEGRATIONS_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  knowledgeSourceConfigurationId: z.string(),
  projectNames: z.string().array(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodUpdateAzureDevOpsIntegrationRequestPayloadDto };
export type { RequestPayloadDto as UpdateAzureDevOpsIntegrationRequestPayloadDto };

// #######
// Request
// #######

export const updateAzureDevopsIntegration = async (
  model: UpdateAzureDevOpsIntegration
): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      knowledgeSourceConfigurationId: model.id,
      projectNames: model.projectsWhitelist ?? [],
    });

    return await apiClient.post(`/Integration/UpdateAzureDevOpsIntegration`, payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseUpdateAzureDevOpsIntegration = {
  onUpdateStart?: () => void;
  onUpdateSuccess?: () => void;
  onUpdateFailed?: (error: Error) => void;
};

export const useUpdateAzureDevOpsIntegration = ({
  onUpdateStart,
  onUpdateSuccess,
  onUpdateFailed,
}: UseUpdateAzureDevOpsIntegration = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (model: UpdateAzureDevOpsIntegration) =>
      await updateAzureDevopsIntegration(model),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: INTEGRATIONS_QUERY_KEYS.integrations(),
      });
    },
    onMutate: () => onUpdateStart?.(),
    onSuccess: () => onUpdateSuccess?.(),
    onError: (error) => onUpdateFailed?.(error),
  });

  return {
    updateIntegration: mutate,
    isUpdatingIntegration: isPending,
    didIntegrationUpdateErrored: isError,
  };
};
