// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { CreateGoogleDriveServiceAccountIntegration } from '../types/google-drive-integration';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { INTEGRATIONS_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  displayName: z.string(),
  serviceAccountKeyJsonString: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodCreateGoogleDriveServiceAccountIntegrationRequestPayloadDto };
export type { RequestPayloadDto as CreateGoogleDriveServiceAccountIntegrationRequestPayloadDto };

// #######
// Request
// #######

export const createGoogleDriveServiceAccountIntegration = async (
  model: CreateGoogleDriveServiceAccountIntegration
): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      displayName: model.displayName.trim(),
      serviceAccountKeyJsonString: model.config.trim(),
    });

    return await apiClient.post(`/Integration/InsertGoogleDriveServiceAccountIntegration`, payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCreateGoogleDriveServiceAccountIntegration = {
  onCreateStart?: () => void;
  onCreateSuccess?: () => void;
  onCreateFailed?: (error: Error) => void;
};

export const useCreateGoogleDriveServiceAccountIntegration = ({
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseCreateGoogleDriveServiceAccountIntegration = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (model: CreateGoogleDriveServiceAccountIntegration) =>
      await createGoogleDriveServiceAccountIntegration(model),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: INTEGRATIONS_QUERY_KEYS.integrations(),
      });
    },
    onMutate: () => onCreateStart?.(),
    onSuccess: () => onCreateSuccess?.(),
    onError: (error) => onCreateFailed?.(error),
  });

  return {
    createIntegration: mutate,
    isCreatingIntegration: isPending,
    didIntegrationCreationErrored: isError,
  };
};
