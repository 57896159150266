// 3rd
import { Flex, Skeleton } from '@chakra-ui/react';

// App - Types
import type { CommunicationSource } from '@/types/integration/communication-source';
import type { CommunicationSourceIntegration } from '../../types/communication-source-integration';

// App - Other
import { Text } from '@/components/atoms/typography';
import { IconBox } from '@/components/molecules/icon-box';
import {
  translateCommunicationSource,
  CommunicationSourceIcon,
} from '@/components/translators/integration/communication-source';

type IntegratedCommunicationSourceCardProps = {
  integration: CommunicationSourceIntegration;
};

export const IntegratedCommunicationSourceCard = ({
  integration,
}: IntegratedCommunicationSourceCardProps) => {
  return (
    <Flex
      direction="column"
      gap="sm"
      bg="surface.primary"
      minW="fit-content"
      width="100%"
      // height="100%"
      height="96px"
      border="1px"
      borderColor="border.primary"
      borderRadius="md"
      overflow="hidden"
      py="lg"
      px="lg"
    >
      <Flex alignItems="start" gap="md">
        <IconBox
          bg="surface.brand.invert-primary-low-tone"
          w="4xl"
          h="4xl"
          minW="4xl"
          minH="4xl"
          borderRadius="md"
        >
          <CommunicationSourceIcon source={integration.source} size="sm" />
        </IconBox>

        <Flex direction="column">
          <Text variant="detail-semibold" color="text.primary">
            {translateCommunicationSource(integration.source as CommunicationSource)}
          </Text>

          <Text variant="detail" color="text.secondary">
            {integration.email}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

const Loading = () => {
  return <Skeleton borderRadius="md" w="100%" h="96px" />;
};

IntegratedCommunicationSourceCard.Loading = Loading;
