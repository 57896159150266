// App - Types
import type { IntegrationStatus } from '@/types/integration/integration-status';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators.integration['integration-status'];

export const translateIntegrationStatus = (status: IntegrationStatus): string => {
  if (status === 'valid') return locale['Valid'];

  if (status === 'installation_removed') return locale['Removed'];

  if (status === 'pending_installation') return locale['Pending'];

  return locale['Unknown'];
};
