// React & Next
import { useState } from 'react';
import type { ReactElement } from 'react';

// 3rd
import { Flex, Fade } from '@chakra-ui/react';

// App - Other
import Locale from '@/locale/en.json';
import { Text } from '@/components/atoms/typography';
import { Button } from '@/components/molecules/button';
import { IconBox } from '@/components/molecules/icon-box';
import { Tag } from '@/components/molecules/tag';

const locale = Locale.features.integrations['knowledge-source-integration-option-card'];

type KnowledgeSourceIntegrationOptionCardProps = {
  title: string;
  description: string;
  icon: ReactElement;
  onClick: (isCtrlKeyPressed: boolean) => void;
  isComingSoon?: boolean;
};

export const IntegrationOptionCard = ({
  title,
  description,
  icon,
  onClick,
  isComingSoon,
}: KnowledgeSourceIntegrationOptionCardProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Flex
      direction="column"
      gap="sm"
      bg={isComingSoon ? 'surface.high-tone' : 'surface.primary'}
      height="100%"
      border="1px"
      borderColor="border.primary"
      borderRadius="md"
      overflow="hidden"
      py="lg"
      px="lg"
      position="relative"
      transition="all 220ms ease-in-out"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      _hover={{ ...(isComingSoon ? {} : { boxShadow: 'lg' }) }}
    >
      <Flex alignItems="flex-start" w="100%" mb={2}>
        <IconBox
          bg="surface.brand.invert-primary-low-tone"
          w="4xl"
          h="4xl"
          minW="4xl"
          minH="4xl"
          borderRadius="md"
        >
          {icon}
        </IconBox>

        <Flex grow={1} />

        {isComingSoon ? (
          <Tag size="sm" variant="primary">
            {locale['COMING SOON']}
          </Tag>
        ) : null}
      </Flex>

      <Text variant="detail-semibold" color={isComingSoon ? 'text.high-tone' : 'text.primary'}>
        {title}
      </Text>

      <Text
        variant="detail"
        color={isComingSoon ? 'text.mid-tone' : 'text.secondary'}
        visibility={!isComingSoon && isHovered ? 'hidden' : 'visible'}
      >
        {description}
      </Text>

      <Fade
        in={!isComingSoon && isHovered}
        style={{
          position: 'absolute',
          bottom: '16px',
          left: '16px',
          right: '16px',
        }}
      >
        <Button
          variant="old.solid"
          w="100%"
          onClick={(e) => onClick(e.metaKey || e.ctrlKey)}
          isDisabled={isComingSoon}
          aria-label={locale['Add integration']}
        >
          {locale['Add integration']}
        </Button>
      </Fade>
    </Flex>
  );
};
