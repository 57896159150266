// React & Next
import type { ReactNode } from 'react';

// 3rd
import { Flex } from '@chakra-ui/react';
import type { StyleProps } from '@chakra-ui/react';

// App - Types
import type { BreadcrumbsProps } from '@/components/organisms/breadcrumbs';

// App - Other
import { Heading } from '@/components/atoms/typography';
import { Breadcrumbs } from '@/components/organisms/breadcrumbs';

type PageLayoutProps = {
  children: ReactNode;
};

export const PaperPageLayout = ({ children }: PageLayoutProps) => {
  return (
    <Flex direction="column" alignItems="flex-start" alignSelf="stretch" gap="lg" py="2xl" px="3xl">
      {children}
    </Flex>
  );
};

type BreadcrumbsBarProps = BreadcrumbsProps;

const BreadcrumbsBar = ({ crumbs }: BreadcrumbsBarProps) => {
  return <Breadcrumbs crumbs={crumbs} />;
};

PaperPageLayout.BreadcrumbsBar = BreadcrumbsBar;

type PaperProps = StyleProps & {
  children: string | ReactNode;
};

const Paper = ({ children, ...styleProps }: PaperProps) => {
  return (
    <Flex
      direction="column"
      alignItems="flex-start"
      alignSelf="stretch"
      gap="xl"
      py="2xl"
      px="2xl"
      border="1px"
      borderColor="border.primary"
      borderRadius="md"
      bg="surface.primary"
      {...styleProps}
    >
      {children}
    </Flex>
  );
};

PaperPageLayout.Paper = Paper;

type HeaderProps = StyleProps & {
  children: ReactNode;
};

const Header = ({ children, ...styleProps }: HeaderProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      alignSelf="stretch"
      py="sm"
      px="none"
      {...styleProps}
    >
      {children}
    </Flex>
  );
};

PaperPageLayout.Header = Header;

type HeaderHeadingProps = StyleProps & {
  children: string | ReactNode;
};

const HeaderHeading = ({ children, ...styleProps }: HeaderHeadingProps) => (
  <Flex
    direction="column"
    alignItems="center"
    justifyContent="center"
    py="sm"
    px="lg"
    borderRadius="sm"
    {...styleProps}
  >
    <Heading variant="h3" color="text.secondary">
      {children}
    </Heading>
  </Flex>
);

PaperPageLayout.Heading = HeaderHeading;

type ContentProps = StyleProps & {
  children: ReactNode;
};

const Content = ({ children, ...styleProps }: ContentProps) => {
  return (
    <Flex alignItems="flex-start" alignSelf="stretch" {...styleProps}>
      {children}
    </Flex>
  );
};

PaperPageLayout.Content = Content;
