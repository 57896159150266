/* eslint-disable @typescript-eslint/no-explicit-any */
// React & Next
import { createElement, useEffect, useMemo } from 'react';

// 3rd
import { Skeleton, forwardRef, Flex } from '@chakra-ui/react';
import type { ComponentWithAs, As, StyleProps } from '@chakra-ui/react';

// App - Types
import type { KnowledgeSource } from '@/types/integration/knowledge-source';

// App - Other
import Locale from '@/locale/en.json';
import { Text } from '@/components/atoms/typography';
import { Select } from '@/components/molecules/form';
import {
  translateKnowledgeSource,
  translateKnowledgeSourceToIcon,
} from '@/components/translators/integration/knowledge-source';

const locale = Locale.features.integrations['integrations-select-source'];

type IntegrationsSelectSourceComponent = ComponentWithAs<As, IntegrationsSelectSourceProps> & {
  Loading: typeof Loading;
};

type SourceOptions = {
  source: KnowledgeSource;
  [key: string]: any;
};

type IntegrationsSelectSourceProps = StyleProps & {
  items: SourceOptions[];
  defaultOptionLabel?: string;
  selected?: KnowledgeSource;
  onChange: (source?: KnowledgeSource) => void;
  onBlur?: () => void;
  isDisabled?: boolean;
  treatGoogleDriveAsOneDrive?: boolean;
};

export const IntegrationsSelectSource = forwardRef(
  (
    {
      items,
      defaultOptionLabel,
      selected,
      onChange,
      onBlur,
      isDisabled,
      treatGoogleDriveAsOneDrive,
      ...props
    }: IntegrationsSelectSourceProps,
    ref
  ) => {
    const defaultOption = useMemo(
      () => ({
        value: '',
        label: <Text variant="detail">{defaultOptionLabel || locale['Select a source']}</Text>,
      }),
      [defaultOptionLabel]
    );

    const options = useMemo(() => {
      const distinctSources = items.reduce((set, option) => {
        return set.add(option.source);
      }, new Set<KnowledgeSource>());

      const sourceOptions = Array.from(distinctSources).map((s) => ({
        value: s,
        label: (
          <Flex alignItems="center" gap="sm">
            {createElement(translateKnowledgeSourceToIcon(s, treatGoogleDriveAsOneDrive), {
              size: '2xs',
              'aria-label': locale['Source icon'],
            })}

            {translateKnowledgeSource(s, treatGoogleDriveAsOneDrive)}
          </Flex>
        ),
      }));

      if (sourceOptions.length > 1) return [defaultOption, ...sourceOptions];

      return sourceOptions;
    }, [items, defaultOption, treatGoogleDriveAsOneDrive]);

    const selectedOption = useMemo(() => {
      if (selected) {
        return {
          value: selected,
          label: (
            <Flex alignItems="center" gap="sm">
              {createElement(translateKnowledgeSourceToIcon(selected, treatGoogleDriveAsOneDrive), {
                size: '2xs',
                'aria-label': locale['Source icon'],
              })}

              {translateKnowledgeSource(selected, treatGoogleDriveAsOneDrive)}
            </Flex>
          ),
        };
      }

      const hasDefaultOption = options.some((option) => option.value === defaultOption.value);

      if (hasDefaultOption) {
        return defaultOption;
      }

      return undefined;
    }, [defaultOption, options, selected, treatGoogleDriveAsOneDrive]);

    useEffect(() => {
      if (!selected && options.length === 1) {
        onChange(options[0].value as KnowledgeSource);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Select
        useBasicStyles
        variant="old.outline"
        size="sm"
        isDisabled={isDisabled}
        options={options}
        value={selectedOption}
        selectedOptionColor="surface.brand.primary"
        placeholder={defaultOptionLabel}
        onBlur={onBlur}
        onChange={(option) => {
          if (!option) {
            return;
          }

          onChange(option.value as KnowledgeSource);
        }}
        ref={ref}
        closeMenuOnSelect={true}
        chakraStyles={{
          container: (styles) => ({ ...styles, ...props }),
          menuList: (styles) => ({ ...styles, ...props }),
        }}
      />
    );
  }
) as IntegrationsSelectSourceComponent;

type LoadingProps = StyleProps;

const Loading = ({ ...props }: LoadingProps) => {
  return <Skeleton {...props} height="26px" />;
};

IntegrationsSelectSource.Loading = Loading;
