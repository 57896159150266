// App - Types
import type { KnowledgeSource } from '../knowledge-source';

export const KnowledgeSourcesDto = [
  'AzureDevOpsWikiPage',
  'AzureDevOpsWorkItem',
  'AzureDevOpsRepository',
  'Confluence',
  'GoogleDrive',
  'Jira',
  'Trello',
  'Linear',
  'Github',
  'OneDrive',
  'Notion',
  'GitLab',
  'UNKNOWN',
] as const;

export type KnowledgeSourceDto = (typeof KnowledgeSourcesDto)[number];

export const castKnowledgeSourceDtoToKnowledgeSource = (
  dto: KnowledgeSourceDto
): KnowledgeSource => {
  if (dto === 'AzureDevOpsWikiPage') return 'azure_devops_wiki_page';

  if (dto === 'AzureDevOpsWorkItem') return 'azure_devops_work_item';

  if (dto === 'AzureDevOpsRepository') return 'azure_devops_repository';

  if (dto === 'Confluence') return 'confluence';

  if (dto === 'GoogleDrive') return 'google_drive';

  if (dto === 'Jira') return 'jira';

  if (dto === 'Trello') return 'trello';

  if (dto === 'Linear') return 'linear';

  if (dto === 'Github') return 'github';

  if (dto === 'OneDrive') return 'one_drive';

  if (dto === 'Notion') return 'notion';

  if (dto === 'GitLab') return 'gitlab';

  return 'UNKNOWN';
};

export const castKnowledgeSourceToKnowledgeSourceDto = (
  knowledgeSource: KnowledgeSource
): KnowledgeSourceDto => {
  if (knowledgeSource === 'azure_devops_wiki_page') return 'AzureDevOpsWikiPage';

  if (knowledgeSource === 'azure_devops_work_item') return 'AzureDevOpsWorkItem';

  if (knowledgeSource === 'azure_devops_repository') return 'AzureDevOpsRepository';

  if (knowledgeSource === 'confluence') return 'Confluence';

  if (knowledgeSource === 'google_drive') return 'GoogleDrive';

  if (knowledgeSource === 'jira') return 'Jira';

  if (knowledgeSource === 'trello') return 'Trello';

  if (knowledgeSource === 'linear') return 'Linear';

  if (knowledgeSource === 'github') return 'Github';

  if (knowledgeSource === 'one_drive') return 'OneDrive';

  if (knowledgeSource === 'notion') return 'Notion';

  if (knowledgeSource === 'gitlab') return 'GitLab';

  return 'UNKNOWN';
};
